import { useAppDispatch } from "../../redux/hooks";
import { logout } from "../../redux/auth/auth.slice";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

export function Logout() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(logout());
        setTimeout(() => navigate("/login"), 1);
    }, [dispatch, navigate]);

    return null;
}
