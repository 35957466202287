import { useAppSelector } from "../redux/hooks";
import { selectAuthRole } from "../redux/auth/auth.selectors";
import { useEffect, useState } from "react";
import { Error403 } from "../components/403";
import { LoginDialog } from "./login/login";

interface PageProps {
    roles: Array<string>;
    children: JSX.Element;
}

export const Page = ({ children, roles }: PageProps) => {
    const role = useAppSelector(selectAuthRole);

    const [restricted, setRestricted] = useState(false);

    useEffect(() => {
        setRestricted(!roles.includes(role));
    }, [role, roles]);

    if (restricted) {
        return (
            <>
                <LoginDialog />
                <Error403 />
            </>
        );
    }

    return (
        <>
            <LoginDialog />
            {children}
        </>
    );
};
