import { apiRequest } from "../../util/api";
import { getEndpointDashboard } from "../../util/constants";

export const getLocationReport = () =>
    apiRequest({
        baseUrl: getEndpointDashboard(),
        url: "/dashboard/cross_report/location",
        method: "GET",
    });

export const getAgentReport = (agentFilterValues: string[] = []) =>
    apiRequest({
        baseUrl: getEndpointDashboard(),
        url: `/dashboard/cross_report/agent?${agentFilterValues.map((f) => `${f}=1`).join("&")}`,
        method: "GET",
    });
